import React from "react"
import styles from "./styles/button.module.css"

const Button = ({onClick, title})=>{
    return(
        <div>
            <button onClick={onClick} className={styles.button}>{title}</button>
        </div>
    )
}

export default Button